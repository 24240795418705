import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Chart from "../static/chart.svg"
import PriceChart from "../components/priceChart"
import RouteStyle from "../components/routeStyle"

import Markdoc from '@markdoc/markdoc';
import yaml from 'js-yaml';

const IndexPage = ({ pageContext }) => {

    const { url, summary, description, responses, parameters } = pageContext;

    const ast = Markdoc.parse(description);
    const meta = yaml.load(ast.attributes.frontmatter);
    const content = Markdoc.transform(ast, /* config */);

    const sections = {
        "pricingDescription": [],
        "languagesDescription": [],
        "fullDescription": []
    };
    let section = "fullDescription";

    content.children.forEach((o, i) => {

        if (o.name == "h2") section = "fullDescription";
        if (o.name == "h2" || o.name == "h1") return;

        sections[section].push(o);

    });
    
    const html = Markdoc.renderers.html(sections.fullDescription);

    return <Layout>
        <section class="mt-32" id="general">
            <div class="mx-auto max-w-xl px-4 py-4">
                <article class="mx-auto h-full lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-3xl">
                        <RouteStyle path={url} />
                    </h1>
                    <p class="text-gray-600 text-left mb-4">
                        {summary}
                    </p>
                </article>
            </div>
        </section>
        <section id="description">
            <div class="mx-auto max-w-xl px-4 py-4">
                <article class="mx-auto h-full lg:mx-0 lg:text-left flex align-top flex-col" dangerouslySetInnerHTML={{ __html: html }}></article>
            </div>
        </section>
        <section id="parameters">
            <div class="mx-auto max-w-xl px-4 py-4">
                <article class="mx-auto h-full lg:mx-0 lg:text-left flex align-top flex-col">
                    <h2 class="text-2xl font-bold">
                        Route Parameters
                    </h2>
                    <p>The route takes in {parameters.length} parameters as input data, which it then processes. The inputs are as follows:</p>
                </article>
            </div>
        </section>
        <section id="responses">
            <div class="mx-auto max-w-xl px-4 py-4">
                <article class="mx-auto h-full lg:mx-0 lg:text-left flex align-top flex-col">
                    <h2 class="text-2xl font-bold">
                        Route Responses
                    </h2>
                    <p className="mb-4">The route has {Object.keys(responses).length} possible responses. No matter what, the server will always return one of these responses.</p>
                    {
                        Object.keys(responses).map(i => {
                            
                            const response = responses[i];

                            return <div className="rounded border overflow-hidden">
                                <div className="w-full flex">
                                    <div className="px-4 py-2 bg-green-200 text-green-800 font-black w-min">{i}</div>
                                    <div className="w-full">
                                        <div>
                                            <b className="block font-bold px-4 py-2">
                                                {
                                                    response['description'] || "No description..."
                                                }
                                            </b>
                                        </div>
                                        <div className="w-full bg-slate-50 px-4 py-2 font-mono text-xs ">
                                            {
                                                Object.keys(response.headers).map(j => {

                                                    const headerVal = response.headers[j]

                                                    return <>{ j + ": " + headerVal }<br /></>;

                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        })
                    }
                </article>
            </div>
        </section>
        <section class="mt-64" id="features"></section>
    </Layout>
}

export const Head = ({ pageContext }) => <Seo title={pageContext.operationId} />

export default IndexPage
